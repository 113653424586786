<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
      duration="15"
    >
      <router-view></router-view>
    </transition>
    <span id="bd-copyright">
      &copy; 2023 SistemCD
    </span>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },

  mounted() {
    if (localStorage.getItem('accessToken')) {
      if (this.$store.getters.getUsers.length === 0) {
        this.$store.dispatch('fetchUsers')
      }
      if (this.$store.getters.getEquipments.length === 0) {
        this.$store.dispatch('fetchEquipments')
      }
      if (this.$store.getters.getItems.length === 0) {
        this.$store.dispatch('fetchItems')
      }
      if (this.$store.getters.getStocks.length === 0) {
        this.$store.dispatch('fetchStocks')
      }
      if (this.$store.getters.getStockItemCategories.length === 0) {
        this.$store.dispatch('fetchStockItemCategories')
      }
    }
  },
}
</script>
<style>
#bd-copyright {
  position: absolute;
  bottom: 2px;
  right: 25px;
  font-size: 9pt;
}
</style>
